import { GetThemeComponents } from "../theme.types";
import { getFontFamilies } from "../utils/get-font-families";

export const cssBaselineTheme: GetThemeComponents<"MuiCssBaseline"> = ({
  theme,
  colorway,
}) => ({
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontFamily: getFontFamilies(theme.fonts?.bodyFont),
        color: colorway?.bodyText,
      },
      "dd, dt, dl": {
        margin: 0,
      },
      address: {
        fontStyle: "normal",
      },
      hr: {
        border: "none",
        height: "1px",
        backgroundColor: colorway?.borderColor?.solid,
      },
    },
  },
});
