import { CustomPaletteColors, GetThemeComponents } from "../theme/theme.types";

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides extends CustomPaletteColors {}
}

export const checkboxTheme: GetThemeComponents<"MuiCheckbox"> = () => ({
  MuiCheckbox: {
    defaultProps: {
      color: "interactive",
    },
  },
});
