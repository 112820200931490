import { CustomPaletteColors, GetThemeComponents } from "../theme/theme.types";

declare module "@mui/material/Stepper" {
  interface StepperPropsColorOverrides extends CustomPaletteColors {}
}

export const stepperTheme: GetThemeComponents<
  | "MuiStepper"
  | "MuiStep"
  | "MuiStepLabel"
  | "MuiStepButton"
  | "MuiStepConnector"
  | "MuiStepContent"
  | "MuiStepIcon"
  | "MuiMobileStepper"
> = () => ({
  MuiStepper: {},
  MuiStep: {},
  MuiStepLabel: {},
  MuiStepButton: {},
  MuiStepConnector: {},
  MuiStepContent: {},
  MuiStepIcon: {},
  MuiMobileStepper: {},
});
