"use client";

import { PropsWithChildren, useEffect, useState } from "react";
import { Box } from "../layout";

export type VisibilityDelayProps = PropsWithChildren<{
  /**
   * Delay the visibility of the children
   * @default 0
   */
  delay?: number;
}>;

export function VisibilityDelay({ delay = 0, children }: VisibilityDelayProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return (
    // @ts-expect-error inert is not in the React types
    <Box
      sx={{
        opacity: isVisible ? 1 : 0,
        transition: "opacity 1s",
      }}
      inert={isVisible ? undefined : ""}
      data-testid="visibility-delay"
    >
      {children}
    </Box>
  );
}
