import { CustomPaletteColors, GetThemeComponents } from "../theme/theme.types";
import { getFontWeight } from "../theme/utils/get-font-weight";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides extends CustomPaletteColors {}
}

export const buttonTheme: GetThemeComponents<
  "MuiButton" | "MuiLoadingButton"
> = ({ theme }) => ({
  MuiLoadingButton: {
    defaultProps: {
      variant: "contained",
      disableElevation: true,
      color: "interactive",
    },
    styleOverrides: {
      root: ({ theme: muiTheme }) => ({
        //FIXME why is this TS not working>?
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        borderRadius: muiTheme.shape.buttonBorderRadius,
        textTransform: "inherit",
        fontWeight: getFontWeight(theme.buttonFontWeight),
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      outlined: {
        borderWidth: "2px",
        ":hover": {
          borderWidth: "2px",
        },
      },
    },
  },
});
