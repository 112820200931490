"use client";

import {
  SnackbarProvider,
  SnackbarProviderProps,
  MaterialDesignContent,
  CustomContentProps,
} from "notistack";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";
import { Typography } from "../typography";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent-default": {},
    "&.notistack-MuiContent-error": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    "&.notistack-MuiContent-success": {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    "&.notistack-MuiContent-info": {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  }),
);

const Toast = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  return (
    <StyledMaterialDesignContent
      {...props}
      ref={ref}
      message={<Typography variant="body2">{props.message}</Typography>}
    />
  );
});

export type ToastProviderProps = SnackbarProviderProps;
export function ToastProvider(props: SnackbarProviderProps) {
  return (
    <SnackbarProvider
      preventDuplicate
      variant="info"
      maxSnack={1}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      {...props}
      Components={{
        default: Toast,
        error: Toast,
        success: Toast,
        info: Toast,
        warning: Toast,
        ...props.Components,
      }}
    />
  );
}
