import { GetThemeComponents } from "../theme/theme.types";

export const linkTheme: GetThemeComponents<"MuiLink"> = () => ({
  MuiLink: {
    defaultProps: {
      underline: "always",
      color: "inherit",
    },
  },
});
