import { TypographyVariant } from "@mui/material/styles";

const lineHeightMap: Partial<Record<TypographyVariant, number>> = {
  h1: 2.5,
  h2: 2,
  h3: 2,
  body1: 1.75,
  body2: 1.5,
  subtitle1: 1,
  subtitle2: 1,
};

export function getFontLineHeight(variant: TypographyVariant = "body1") {
  const lineHeight = lineHeightMap[variant] ?? 1;
  return `${lineHeight}rem`;
}
