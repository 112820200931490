"use client";

import { useEffect, useState } from "react";

export function useIsResizingWindow() {
  const [isResizingWindow, setIsResizingWindow] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsResizingWindow(true);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isResizingWindow) {
      const timer = setTimeout(() => setIsResizingWindow(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isResizingWindow]);

  return { isResizingWindow };
}
