"use client";

import { Box, Stack } from "../layout";
import { Link } from "../link";

export type PoweredByLavandaProps = {
  label?: string;
  alt?: string;
  ariaLabel: string;
};

export function PoweredByLavanda({
  label = "Powered by",
  alt = "Lavanda logo",
  ariaLabel,
}: PoweredByLavandaProps) {
  return (
    <Stack
      direction="row"
      aria-label={ariaLabel}
      component={Link}
      underline="none"
      href="https://getlavanda.com"
      target="_blank"
      rel="noopener"
      sx={(theme) => ({
        background: theme.palette.lavanda.main,
        color: theme.palette.lavanda.contrastText,
        borderRadius: "0.5rem",
        p: 2,
        whiteSpace: "nowrap",
        minWidth: "fit-content",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        fontFamily: `Outfit, ${theme.typography.fontFamily}`,
        fontWeight: 400,
      })}
    >
      {label}
      <Box
        component="img"
        alt={alt}
        src="https://res.cloudinary.com/lavanda/image/upload/v1730209627/brand_assets/logos/Lavanda_Logo.png"
        sx={{
          height: "1.2em",
          width: "auto",
        }}
      />
    </Stack>
  );
}
