import {
  Theme as MuiTheme,
  PaletteColor,
  PaletteOptions,
  lighten,
  getContrastRatio,
  darken,
} from "@mui/material/styles";
import { CustomPaletteColors, ThemeConfig } from "../theme.types";

type GetThemePaletteOptions = ThemeConfig & { muiTheme: MuiTheme };
type GetThemePaletteReturn = PaletteOptions & CustomPaletteColors<PaletteColor>;

export function getThemePalette({
  theme,
  colorway,
  muiTheme,
}: GetThemePaletteOptions): GetThemePaletteReturn {
  const { augmentColor } = muiTheme.palette;

  const mode =
    !!colorway?.background && getContrastRatio(colorway.background, "#fff") > 3
      ? "dark"
      : "light";

  return {
    mode,
    primary: augmentColor({
      color: {
        main: colorway?.colorVariants.primary?.background || "#000",
        contrastText: colorway?.colorVariants.primary?.bodyText,
      },
    }),
    secondary: augmentColor({
      color: {
        main: colorway?.colorVariants.secondary?.background || "#000",
        contrastText: colorway?.colorVariants.secondary?.bodyText,
      },
    }),
    tertiary: augmentColor({
      color: {
        main: colorway?.colorVariants.tertiary?.background || "#000",
        contrastText: colorway?.colorVariants.tertiary?.bodyText,
      },
    }),
    error: augmentColor({
      color: {
        main: theme.alerts.error.background || "#000",
        contrastText: theme.alerts.error.color,
      },
    }),
    warning: augmentColor({
      color: {
        main: theme.alerts.warning.background || "#000",
        contrastText: theme.alerts.warning.color,
      },
    }),
    info: augmentColor({
      color: {
        main: theme.alerts.info.background || "#000",
        contrastText: theme.alerts.info.color,
      },
    }),
    success: augmentColor({
      color: {
        main: theme.alerts.success.background || "#000",
        contrastText: theme.alerts.success.color,
      },
    }),
    background: {
      default: colorway?.background,
      paper:
        mode === "dark"
          ? lighten(colorway?.background || "#000", 0.05)
          : darken(colorway?.background || "#000", 0.05),
    },
    text: {
      primary: colorway?.bodyText,
      secondary: colorway?.bodyText,
    },
    interactive: augmentColor({
      name: "interactive",
      color: {
        main: colorway?.interactiveElements || "#000",
      },
    }),
    highlight: augmentColor({
      name: "highlight",
      color: {
        main: colorway?.highlightText || "#000",
      },
    }),
    subtitle: augmentColor({
      name: "subtitle",
      color: {
        main: colorway?.subtitleText || "#000",
      },
    }),
    common: {
      border: colorway?.borderColor?.solid,
    },
    lavanda: augmentColor({
      name: "lavanda",
      color: {
        main: "#E0E7FF",
      },
    }),
    lavandaGreen: augmentColor({
      name: "lavandaGreen",
      color: {
        main: "#72C4B9",
      },
    }),
    lavandaBlue: augmentColor({
      name: "lavandaBlue",
      color: {
        main: "#5578C9",
      },
    }),
    lavandaYellow: augmentColor({
      name: "lavandaYellow",
      color: {
        main: "#F7CD87",
      },
    }),
    lavandaRed: augmentColor({
      name: "lavandaRed",
      color: {
        main: "#C71F1F",
      },
    }),
    lavandaGrey: augmentColor({
      name: "lavandaGrey",
      color: {
        main: "#637892",
      },
    }),
  };
}
