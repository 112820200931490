import { ColorwayName, Theme as PlatApiTheme } from "../graphql/generated";
import { ThemeOptions } from "@mui/material/styles";

export { ColorwayName };
export type Theme = PlatApiTheme;

type Components = NonNullable<ThemeOptions["components"]>;

/**
 * The function signature for each component to adjust its own styling
 * e.g. `text-field.theme.ts`. Keys defined as the generic define the components
 * you wish to adjust the theme for. A union can be provided to adjust multiple
 * components.
 *
 * @example
 * export const buttonTheme: GetThemeComponents<"MuiTextField" | "MuiInputLabel"> = ({ theme }) => ({
 *    MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
      },
 * })
 */
export type GetThemeComponents<C extends keyof Components> = (
  config: ThemeConfig,
) => Required<Pick<Components, C>>;

/**
 * The common interface for passing around the theme and colorway
 */
export type ThemeConfig = {
  theme: Theme;
  colorwayName: ColorwayName;
  colorway: Theme["colorways"][ColorwayName];
};

/**
 * The function signature for each of the high level theme options
 * such as `"palette"`, `"typography"` etc.
 */
export type GetThemeConfig<Option extends keyof ThemeOptions> = (
  config: ThemeConfig,
) => NonNullable<ThemeOptions[Option]>;

export type CustomPaletteColors<T = true> = {
  interactive: T;
  tertiary: T;
  subtitle: T;
  highlight: T;
  lavanda: T;
  lavandaBlue: T;
  lavandaYellow: T;
  lavandaRed: T;
  lavandaGrey: T;
  lavandaGreen: T;
};

export type CustomCommonColors<T = true> = {
  border: T;
};
