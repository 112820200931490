import { GetThemeConfig } from "../theme.types";

export const getThemeBreakpoints: GetThemeConfig<"breakpoints"> = () => ({
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    mobile: 900,
  },
});
