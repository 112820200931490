import { Radius } from "../../graphql/generated";

const radiusMap: Record<Radius, number> = {
  [Radius.None]: 0,
  [Radius.Small]: 4,
  [Radius.Medium]: 8,
  [Radius.Large]: 16,
  [Radius.Full]: 9999,
};

export function getBorderRadius(radius: Radius | undefined | null) {
  return radiusMap[radius || Radius.Medium] ?? radiusMap[Radius.Medium];
}
