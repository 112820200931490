"use client";

import { forwardRef, ReactNode, useCallback } from "react";
import ReactCountdown, {
  CountdownProps as ReactCountdownProps,
  CountdownRenderProps,
  CountdownRendererFn,
} from "react-countdown";

const ONE_MINUTE_MS = 60 * 1000;
const ONE_HOUR_MS = 60 * ONE_MINUTE_MS;
const ONE_DAY_MS = 24 * ONE_HOUR_MS;

export type { CountdownRenderProps, CountdownRendererFn };

type Units = {
  days?: string;
  hours?: string;
  minutes?: string;
  seconds?: string;
};

export type CountdownRef = ReactCountdown;
export type CountdownProps = ReactCountdownProps & {
  overrideRenderer?: boolean;
  units?: Units;
};

function getText(
  options: CountdownRenderProps,
  { days = "d", hours = "h", minutes = "m", seconds = "s" }: Units = {},
): ReactNode {
  const timeComponents = [
    options.total >= ONE_DAY_MS ? `${options.days}${days}` : null,
    options.total >= ONE_HOUR_MS ? `${options.hours}${hours}` : null,
    options.total >= ONE_MINUTE_MS ? `${options.minutes}${minutes}` : null,
    `${options.seconds}${seconds}`,
  ];

  return timeComponents.filter(Boolean).join(" ");
}

/**
 * A wrapper around [react-countdown](https://www.npmjs.com/package/react-countdown) that provides a default renderer
 */
export const Countdown = forwardRef<CountdownRef, CountdownProps>(
  ({ overrideRenderer, renderer: providedRenderer, units, ...rest }, ref) => {
    const renderer = useCallback<CountdownRendererFn>(
      (options) => {
        if (overrideRenderer && providedRenderer) {
          return providedRenderer(options);
        }

        return (
          <span role="timer" suppressHydrationWarning>
            {providedRenderer
              ? providedRenderer(options)
              : getText(options, units)}
          </span>
        );
      },
      [overrideRenderer, providedRenderer, units],
    );

    return <ReactCountdown {...rest} renderer={renderer} ref={ref} />;
  },
);
