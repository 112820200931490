import { GetThemeConfig } from "../theme.types";
import { avatarTheme } from "../../avatar/avatar.theme";
import { buttonTheme } from "../../button/button.theme";
import { iconButtonTheme } from "../../icon-button/icon-button.theme";
import { formControlTheme } from "../../form-control/form-control.theme";
import { stackTheme } from "../../layout/stack.theme";
import { linkTheme } from "../../link/link.theme";
import { listTheme } from "../../list/list.theme";
import { radioTheme } from "../../radio/radio.theme";
import { skeletonTheme } from "../../skeleton/skeleton.theme";
import { textFieldTheme } from "../../text-field/text-field.theme";
import { cssBaselineTheme } from "../sections/css-baseline.theme";
import { checkboxTheme } from "../../checkbox/checkbox.theme";
import { progressTheme } from "../../progress/progress.theme";
import { stepperTheme } from "../../stepper/stepper.theme";

const componentThemes = [
  avatarTheme,
  buttonTheme,
  formControlTheme,
  stackTheme,
  linkTheme,
  listTheme,
  radioTheme,
  skeletonTheme,
  textFieldTheme,
  cssBaselineTheme,
  iconButtonTheme,
  checkboxTheme,
  progressTheme,
  stepperTheme,
] as const;

export const getComponents: GetThemeConfig<"components"> = (config) => {
  // Combine all the component themes into one object and give them the brand theme
  return componentThemes.reduce(
    (acc, componentTheme) => {
      return {
        ...acc,
        ...componentTheme(config),
      };
    },
    {} as ReturnType<GetThemeConfig<"components">>,
  );
};
