import { CustomPaletteColors, GetThemeComponents } from "../theme/theme.types";

declare module "@mui/material/Radio" {
  interface RadioPropsColorOverrides extends CustomPaletteColors {}
}

export const radioTheme: GetThemeComponents<"MuiRadio"> = () => ({
  MuiRadio: {
    defaultProps: {
      color: "interactive",
    },
  },
});
