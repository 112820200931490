import { CustomPaletteColors, GetThemeComponents } from "../theme/theme.types";
import { getFontLineHeight } from "../theme/utils/get-font-line-height";
import { getFontSize } from "../theme/utils/get-font-size";
import { TextFieldProps } from "./types/text-field-props";

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides extends CustomPaletteColors {}
}

export const textFieldTheme: GetThemeComponents<
  "MuiTextField" | "MuiInputLabel" | "MuiInput" | "MuiInputBase"
> = ({ theme }) => {
  const variant: TextFieldProps["variant"] = theme.formFieldStyle || "standard";

  return {
    MuiTextField: {
      defaultProps: {
        variant,
        color: "interactive",
      },
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: variant === "outlined" ? undefined : true,
      },
      styleOverrides: {
        root: ({ theme: muiTheme }) => ({
          color: muiTheme.palette.text.primary,
          "&.Mui-focused": {
            color: muiTheme.palette.text.primary,
          },
          "&.Mui-error": {
            color: muiTheme.palette.highlight.main,
          },
        }),
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          "& svg": {
            color: "inherit",
          },
        },
        underline: ({ theme: muiTheme }) => ({
          "&:before": {
            borderColor: muiTheme.palette.text.primary,
          },
          "&:after": {
            borderColor: muiTheme.palette.text.primary,
          },
          "&.Mui-disabled": {
            "&:before": {
              borderBottom: `1px solid ${muiTheme.palette.action.disabled}`,
            },
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme: muiTheme }) => ({
          "&.Mui-disabled": {
            "&:before": {
              borderBottomStyle: "solid !important",
            },
          },
          "& > *": {
            height: "auto",
            fontSize: getFontSize(),
            lineHeight: getFontLineHeight(),
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${muiTheme.palette.highlight.main} !important`,
          },
        }),
        input: {
          "&::-webkit-search-cancel-button": {
            display: "none",
          },
        },
      },
    },
  };
};
