import { FontWeight } from "../../graphql/generated";

const fontWeightMap: Record<FontWeight, number> = {
  [FontWeight.ExtraLight]: 200,
  [FontWeight.Light]: 300,
  [FontWeight.Normal]: 400,
  [FontWeight.Bold]: 500,
  [FontWeight.ExtraBold]: 600,
};

export function getFontWeight(fontWeight: FontWeight | null | undefined) {
  return (
    fontWeightMap[fontWeight || FontWeight.Normal] ??
    fontWeightMap[FontWeight.Normal]
  );
}
