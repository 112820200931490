import { createTheme } from "@mui/material/styles";
import { ThemeConfig } from "./theme.types";
import { getComponents } from "./sections/theme.components";
import { getThemePalette } from "./sections/theme.palette";
import { getThemeTypography } from "./sections/theme.typography";
import { getThemeShape } from "./sections/theme.shape";
import { getThemeBreakpoints } from "./sections/theme.breakpoints";

export function getMuiTheme(config: ThemeConfig) {
  // Use MUI's default theme if no theme is provided
  if (!config.theme) {
    return createTheme();
  }

  const muiTheme = createTheme({
    typography: getThemeTypography(config),
    shape: getThemeShape(config),
    breakpoints: getThemeBreakpoints(config),
  });

  return createTheme(muiTheme, {
    components: getComponents(config),
    palette: getThemePalette({ ...config, muiTheme }),
  });
}
