"use client";

import {
  enqueueSnackbar,
  VariantType as NotistackVariantType,
  SnackbarMessage,
  OptionsWithExtraProps,
} from "notistack";

export type VariantType = Exclude<NotistackVariantType, "default">;
export type AddToastOptions<V extends VariantType> = OptionsWithExtraProps<V>;

export function addToast<V extends VariantType>(
  message: SnackbarMessage,
  options?: AddToastOptions<V>,
) {
  return enqueueSnackbar(message, options);
}
