import { GetThemeComponents } from "../theme/theme.types";

export const formControlTheme: GetThemeComponents<
  | "MuiFormControl"
  | "MuiFormControlLabel"
  | "MuiFormHelperText"
  | "MuiFormLabel"
> = () => ({
  MuiFormControl: {},
  MuiFormControlLabel: {},
  MuiFormLabel: {
    styleOverrides: {
      root: {
        "&.Mui-focused": {
          color: "inherit",
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme: muiTheme }) => ({
        "&.Mui-error": {
          color: muiTheme.palette.highlight.main,
        },
      }),
    },
  },
});
