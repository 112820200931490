import { CustomPaletteColors, GetThemeComponents } from "../theme/theme.types";

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides extends CustomPaletteColors {}
}

export const iconButtonTheme: GetThemeComponents<"MuiIconButton"> = () => ({
  MuiIconButton: {
    defaultProps: {
      color: "inherit",
    },
    styleOverrides: {
      root: {
        padding: "6px",
      },
    },
  },
});
