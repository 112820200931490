export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Color: { input: any; output: any; }
  DateTime: { input: string; output: string; }
  ISO8601Date: { input: string; output: string; }
  ISO8601DateTime: { input: string; output: string; }
  JSON: { input: any; output: any; }
  Metadata: { input: any; output: any; }
  SupportedLocale: { input: any; output: any; }
  UUID: { input: string; output: string; }
};

export type Accommodation = {
  __typename?: 'Accommodation';
  checkIn: Scalars['String']['output'];
  checkOut: Scalars['String']['output'];
  unit?: Maybe<Unit>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  line3?: Maybe<Scalars['String']['output']>;
  line4?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type AlertColors = {
  __typename?: 'AlertColors';
  background: Scalars['Color']['output'];
  color: Scalars['Color']['output'];
};

export type AlertColorsInput = {
  background: Scalars['Color']['input'];
  color: Scalars['Color']['input'];
};

export enum AlertSeverity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

export type Alerts = {
  __typename?: 'Alerts';
  error: AlertColors;
  info: AlertColors;
  success: AlertColors;
  warning: AlertColors;
};

export type AlertsInput = {
  error: AlertColorsInput;
  info: AlertColorsInput;
  success: AlertColorsInput;
  warning: AlertColorsInput;
};

/** Autogenerated input type of AllocateDocumentMutation */
export type AllocateDocumentMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documents: Array<DocumentAllocationInput>;
  uuid: Scalars['ID']['input'];
};

export type AllocateDocumentMutations = {
  __typename?: 'AllocateDocumentMutations';
  document?: Maybe<Document>;
};


export type AllocateDocumentMutationsDocumentArgs = {
  input: AllocateDocumentMutationInput;
};

export type Allocation = {
  __typename?: 'Allocation';
  linkedTransactionAmountAllocatedCents: Scalars['Int']['output'];
  linkedTransactionDate: Scalars['ISO8601Date']['output'];
  linkedTransactionInvoiceNumber: Scalars['String']['output'];
  linkedTransactionType: Scalars['String']['output'];
  linkedTransactionUuid: Scalars['String']['output'];
};

export type AnalysisReport = {
  __typename?: 'AnalysisReport';
  lineItems?: Maybe<Array<LineItem>>;
  metadata?: Maybe<Scalars['Metadata']['output']>;
};

export type AuthIdentityQueries = {
  __typename?: 'AuthIdentityQueries';
  pms?: Maybe<Pms>;
};

export type Availability = {
  __typename?: 'Availability';
  /** @deprecated Use units_availability field instead */
  isUnitAvailable?: Maybe<UnitAvailability>;
  unitsAvailability?: Maybe<UnitAvailabilityConnection>;
};


export type AvailabilityIsUnitAvailableArgs = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  unitId: Scalars['ID']['input'];
};


export type AvailabilityUnitsAvailabilityArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<UnitsAvailabilityInput>;
};

export enum AvailabilityStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

export type AwcQueries = {
  __typename?: 'AwcQueries';
  workspaces?: Maybe<Workspaces>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  bankAccountBeneficiary?: Maybe<Scalars['String']['output']>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  bankFullAddress?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  bankSwiftbic?: Maybe<Scalars['String']['output']>;
};

export type BatchUpdateTariffs = {
  __typename?: 'BatchUpdateTariffs';
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated input type of BatchUpdateTariffs */
export type BatchUpdateTariffsInput = {
  batchUpdateTariffs: BatchUpdateTariffsInputType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type BatchUpdateTariffsInputType = {
  dates: Array<Array<Scalars['ISO8601Date']['input']>>;
  interval: PricingIntervalEnum;
  price: PriceInput;
  spaces: Array<SpaceInput>;
};

export type Booking = {
  __typename?: 'Booking';
  accommodations?: Maybe<Array<Accommodation>>;
  cancelable?: Maybe<Scalars['Boolean']['output']>;
  checkIn?: Maybe<Scalars['ISO8601Date']['output']>;
  checkOut?: Maybe<Scalars['ISO8601Date']['output']>;
  confirmationCode?: Maybe<Scalars['String']['output']>;
  contractStatus?: Maybe<ContractStatus>;
  downPaymentStatus?: Maybe<DownPaymentStatus>;
  editable?: Maybe<Scalars['Boolean']['output']>;
  externalBookingId?: Maybe<Scalars['String']['output']>;
  guestsCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  nightsCount?: Maybe<Scalars['Int']['output']>;
  paymentStatus?: Maybe<PaymentStatus>;
  people?: Maybe<Array<Person>>;
  platform?: Maybe<Scalars['String']['output']>;
  relocatable?: Maybe<Scalars['Boolean']['output']>;
  sepaStatus?: Maybe<SepaStatus>;
  status?: Maybe<BookingStatus>;
  vetting?: Maybe<Vetting>;
};

/** The connection type for Booking. */
export type BookingConnection = {
  __typename?: 'BookingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BookingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Booking>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BookingEdge = {
  __typename?: 'BookingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Booking>;
};

export enum BookingStatus {
  AwaitingPayment = 'AWAITING_PAYMENT',
  Canceled = 'CANCELED',
  CheckedIn = 'CHECKED_IN',
  CheckedOut = 'CHECKED_OUT',
  Closed = 'CLOSED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Inquiry = 'INQUIRY',
  Requested = 'REQUESTED',
  Reserved = 'RESERVED'
}

export enum BookingWindow {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Year = 'year'
}

export type BookingsMutations = {
  __typename?: 'BookingsMutations';
  moveBooking?: Maybe<MoveBookingMutation>;
  splitBooking?: Maybe<SplitBookingMutation>;
};


export type BookingsMutationsMoveBookingArgs = {
  input: MoveBookingMutationInput;
};


export type BookingsMutationsSplitBookingArgs = {
  input: SplitBookingMutationInput;
};

export type BookingsQueries = {
  __typename?: 'BookingsQueries';
  confirmationCodeVerify?: Maybe<Booking>;
  search?: Maybe<BookingConnection>;
};


export type BookingsQueriesConfirmationCodeVerifyArgs = {
  q: ConfirmationCodeVerifyInput;
};


export type BookingsQueriesSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<BookingsSearchInput>;
};

export type BookingsSearchInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BorderColor = {
  __typename?: 'BorderColor';
  dashed?: Maybe<Scalars['Color']['output']>;
  solid?: Maybe<Scalars['Color']['output']>;
};

export type BorderColorInput = {
  dashed?: InputMaybe<Scalars['Color']['input']>;
  solid?: InputMaybe<Scalars['Color']['input']>;
};

export enum BorderStyle {
  Dashed = 'dashed',
  None = 'none',
  Solid = 'solid'
}

export type Borders = {
  __typename?: 'Borders';
  style: BorderStyle;
  width?: Maybe<Scalars['String']['output']>;
};

export type BordersInput = {
  style: BorderStyle;
  width?: InputMaybe<Scalars['String']['input']>;
};

export type Brand = {
  __typename?: 'Brand';
  displayName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  socials: Array<Maybe<Social>>;
  supportedLocales: Array<Maybe<Scalars['SupportedLocale']['output']>>;
  theme: Theme;
};

export type BrandInput = {
  displayName: Scalars['String']['input'];
  socials: Array<InputMaybe<SocialInput>>;
  supportedLocales: Array<InputMaybe<Scalars['SupportedLocale']['input']>>;
  theme: ThemeInput;
};

export type BrandResponse = Brand | ErrorMessage;

export type Building = {
  __typename?: 'Building';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  workspace?: Maybe<Workspace>;
};

/** The connection type for Building. */
export type BuildingConnection = {
  __typename?: 'BuildingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BuildingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Building>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BuildingEdge = {
  __typename?: 'BuildingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Building>;
};

export type BuildingsSearchQuery = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum ButtonFontWeight {
  Bold = 'bold',
  Light = 'light',
  Normal = 'normal'
}

export type CloneMutation = {
  __typename?: 'CloneMutation';
  offerId: Scalars['ID']['output'];
};

/** Autogenerated input type of CloneOfferMutation */
export type CloneOfferMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['ID']['input'];
};

export type ColorDetail = {
  __typename?: 'ColorDetail';
  background: Scalars['Color']['output'];
  bodyText: Scalars['Color']['output'];
};

export type ColorDetailInput = {
  background: Scalars['Color']['input'];
  bodyText: Scalars['Color']['input'];
};

export enum ColorVariant {
  Destructive = 'destructive',
  OnColorway = 'onColorway',
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary'
}

export type ColorVariants = {
  __typename?: 'ColorVariants';
  destructive?: Maybe<ColorDetail>;
  onColorway?: Maybe<ColorDetail>;
  primary?: Maybe<ColorDetail>;
  secondary?: Maybe<ColorDetail>;
  tertiary?: Maybe<ColorDetail>;
};

export type ColorVariantsInput = {
  destructive?: InputMaybe<ColorDetailInput>;
  onColorway?: InputMaybe<ColorDetailInput>;
  primary?: InputMaybe<ColorDetailInput>;
  secondary?: InputMaybe<ColorDetailInput>;
  tertiary?: InputMaybe<ColorDetailInput>;
};

export type Colorway = {
  __typename?: 'Colorway';
  background: Scalars['Color']['output'];
  bodyText: Scalars['Color']['output'];
  borderColor?: Maybe<BorderColor>;
  colorVariants: ColorVariants;
  dropShadow?: Maybe<Scalars['Color']['output']>;
  headerText: Scalars['Color']['output'];
  highlightText?: Maybe<Scalars['Color']['output']>;
  images?: Maybe<Images>;
  interactiveElements?: Maybe<Scalars['Color']['output']>;
  jumbotronText?: Maybe<Scalars['Color']['output']>;
  subtitleText?: Maybe<Scalars['Color']['output']>;
};

export type ColorwayInput = {
  background: Scalars['Color']['input'];
  bodyText: Scalars['Color']['input'];
  borderColor?: InputMaybe<BorderColorInput>;
  colorVariants: ColorVariantsInput;
  dropShadow?: InputMaybe<Scalars['Color']['input']>;
  headerText: Scalars['Color']['input'];
  highlightText?: InputMaybe<Scalars['Color']['input']>;
  images?: InputMaybe<ImagesInput>;
  interactiveElements?: InputMaybe<Scalars['Color']['input']>;
  jumbotronText?: InputMaybe<Scalars['Color']['input']>;
  subtitleText?: InputMaybe<Scalars['Color']['input']>;
};

export enum ColorwayName {
  Colorway1 = 'colorway1',
  Colorway2 = 'colorway2',
  Colorway3 = 'colorway3',
  Dark = 'dark',
  Light = 'light',
  White = 'white'
}

export type Colorways = {
  __typename?: 'Colorways';
  colorway1?: Maybe<Colorway>;
  colorway2?: Maybe<Colorway>;
  colorway3?: Maybe<Colorway>;
  dark?: Maybe<Colorway>;
  light?: Maybe<Colorway>;
  white?: Maybe<Colorway>;
};

export type ColorwaysInput = {
  colorway1?: InputMaybe<ColorwayInput>;
  colorway2?: InputMaybe<ColorwayInput>;
  colorway3?: InputMaybe<ColorwayInput>;
  dark?: InputMaybe<ColorwayInput>;
  light?: InputMaybe<ColorwayInput>;
  white?: InputMaybe<ColorwayInput>;
};

export type ConfirmationCodeVerifyInput = {
  confirmationCode: Scalars['String']['input'];
  surname: Scalars['String']['input'];
};

export type Contact = {
  __typename?: 'Contact';
  name?: Maybe<Scalars['String']['output']>;
};

export enum ContractStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type ConvertedAmountDto = {
  __typename?: 'ConvertedAmountDTO';
  amount: Scalars['Float']['output'];
};

/** Autogenerated input type of CreateMarketplaceAppMutation */
export type CreateMarketplaceAppMutationInput = {
  app: MarketplaceAppInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreatePaymentIntentMutation */
export type CreatePaymentIntentMutationInput = {
  bookingId?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoiceUuid?: InputMaybe<Scalars['String']['input']>;
  payment: PaymentIntentInput;
  workspaceId: Scalars['Int']['input'];
};

export type CreateResidentAppConfigInput = {
  authClientId: Scalars['String']['input'];
  authClientSecret: Scalars['String']['input'];
  authIssuerUrl: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
  cmsApiKey?: InputMaybe<Scalars['String']['input']>;
  colorway: ColorwayName;
  domains: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateUserBookingInput = {
  configId: Scalars['String']['input'];
  confirmationCode: Scalars['String']['input'];
  surname: Scalars['String']['input'];
};

export type CrmContact = {
  __typename?: 'CrmContact';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  primaryEmail?: Maybe<Scalars['String']['output']>;
};

export type CrmNamespace = {
  __typename?: 'CrmNamespace';
  buildings?: Maybe<BuildingConnection>;
  name?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};


export type CrmNamespaceBuildingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for CrmNamespace. */
export type CrmNamespaceConnection = {
  __typename?: 'CrmNamespaceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CrmNamespaceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CrmNamespace>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CrmNamespaceEdge = {
  __typename?: 'CrmNamespaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CrmNamespace>;
};

export type CrmQueries = {
  __typename?: 'CrmQueries';
  crmNamespaces?: Maybe<CrmNamespaceConnection>;
  leads?: Maybe<LeadConnection>;
};


export type CrmQueriesCrmNamespacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CrmQueriesLeadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<LeadsSearchQuery>;
};

export type Dna = {
  __typename?: 'DNA';
  superset: Superset;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  category: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DbwSite = {
  __typename?: 'DbwSite';
  brand?: Maybe<Scalars['String']['output']>;
  buildings?: Maybe<BuildingConnection>;
  cancellationPolicy?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  hosts?: Maybe<Array<Host>>;
  name: Scalars['String']['output'];
  ndbwChannelFeePercentage: Scalars['Float']['output'];
  paymentGatewayType?: Maybe<PaymentGatewayTypeEnum>;
  privacyPolicy?: Maybe<Scalars['String']['output']>;
  searchTermTypes?: Maybe<SearchTermTypeConnection>;
  searchTerms?: Maybe<SearchTermConnection>;
  stripeApiPublic?: Maybe<Scalars['String']['output']>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
};


export type DbwSiteBuildingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DbwSiteSearchTermTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DbwSiteSearchTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DbwSitesSearchQuery = {
  code?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteUserBookingsInput = {
  bookingIds: Array<Scalars['String']['input']>;
  configId: Scalars['String']['input'];
};

export type Document = {
  __typename?: 'Document';
  allocatedCents?: Maybe<Scalars['Int']['output']>;
  allocations?: Maybe<Array<Allocation>>;
  analysisReport?: Maybe<AnalysisReport>;
  balanceCents?: Maybe<Scalars['Int']['output']>;
  billingPeriodFrom?: Maybe<Scalars['ISO8601Date']['output']>;
  billingPeriodTo?: Maybe<Scalars['ISO8601Date']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  displayInvoiceNumber?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  invoiceNumber?: Maybe<Scalars['Int']['output']>;
  invoiceType?: Maybe<InvoiceType>;
  issueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  issuedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lineItems?: Maybe<Array<LineItem>>;
  metadata?: Maybe<Scalars['Metadata']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Recipient>;
  referencedInvoices?: Maybe<Array<InvoiceReference>>;
  requiresAction?: Maybe<Scalars['Boolean']['output']>;
  sender?: Maybe<Sender>;
  status?: Maybe<Scalars['String']['output']>;
  taxRegime?: Maybe<Scalars['String']['output']>;
  taxesSummary?: Maybe<TaxesSummary>;
  totalCents?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
  workspaceUuid?: Maybe<Scalars['ID']['output']>;
};

export type DocumentAllocationInput = {
  amountCents: Scalars['Int']['input'];
  uuid: Scalars['ID']['input'];
};

export enum DownPaymentStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED'
}

export enum Elevation {
  Diffuse = 'diffuse',
  None = 'none'
}

export type Error = {
  __typename?: 'Error';
  error?: Maybe<ErrorInformation>;
  intervalPricingCalendarId?: Maybe<Scalars['Int']['output']>;
};

export type ErrorInformation = {
  __typename?: 'ErrorInformation';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ErrorMessage = {
  __typename?: 'ErrorMessage';
  data?: Maybe<Scalars['String']['output']>;
  error: Scalars['String']['output'];
  statusCode?: Maybe<Scalars['Int']['output']>;
};

export enum FontSize {
  Base = 'base',
  Xl = 'xl',
  Xl2 = 'xl2',
  Xl3 = 'xl3',
  Xl4 = 'xl4',
  Xl5 = 'xl5',
  Xs = 'xs',
  Xs2 = 'xs2'
}

export enum FontVariant {
  Normal = 'normal'
}

export enum FontWeight {
  Bold = 'bold',
  ExtraBold = 'extraBold',
  ExtraLight = 'extraLight',
  Light = 'light',
  Normal = 'normal'
}

export type Fonts = {
  __typename?: 'Fonts';
  bodyFont: Scalars['String']['output'];
  filePaths: Array<Maybe<Scalars['String']['output']>>;
  headerFont: Scalars['String']['output'];
  jumbotronFont?: Maybe<Scalars['String']['output']>;
  notifications?: Maybe<NotificationFonts>;
};

export type FontsInput = {
  bodyFont: Scalars['String']['input'];
  headerFont: Scalars['String']['input'];
  jumbotronFont?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationFontsInput>;
};

export type Forex = {
  __typename?: 'Forex';
  convert: ConvertedAmountDto;
  latestRate: Rate;
  rateAtTime: Rate;
  rates: Array<Rate>;
};


export type ForexConvertArgs = {
  amount: Scalars['Float']['input'];
  currencyFrom: Scalars['String']['input'];
  currencyTo: Scalars['String']['input'];
  time?: Scalars['DateTime']['input'];
};


export type ForexLatestRateArgs = {
  currencyFrom: Scalars['String']['input'];
  currencyTo: Scalars['String']['input'];
};


export type ForexRateAtTimeArgs = {
  currencyFrom: Scalars['String']['input'];
  currencyTo: Scalars['String']['input'];
  time: Scalars['DateTime']['input'];
};

export type ForgetPasswordInput = {
  email: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export enum FormFieldStyle {
  Filled = 'filled',
  Outlined = 'outlined',
  Standard = 'standard'
}

export enum Gap {
  Large = 'large',
  Medium = 'medium',
  None = 'none',
  Small = 'small'
}

export type GuestPayment = {
  __typename?: 'GuestPayment';
  amount: Scalars['Float']['output'];
  automatic?: Maybe<Scalars['Boolean']['output']>;
  cardLastFour?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  documentUuid?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['String']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['String']['output']>;
  payerName?: Maybe<Scalars['String']['output']>;
  payerToken?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentReference?: Maybe<Scalars['String']['output']>;
  payoutMethod?: Maybe<Scalars['String']['output']>;
  payoutSource?: Maybe<Scalars['String']['output']>;
  processError?: Maybe<Scalars['String']['output']>;
  processedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  refundedAmount?: Maybe<Scalars['Float']['output']>;
  refundedPayments?: Maybe<RefundGuestPaymentConnection>;
  status?: Maybe<Scalars['String']['output']>;
};


export type GuestPaymentRefundedPaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for GuestPayment. */
export type GuestPaymentConnection = {
  __typename?: 'GuestPaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GuestPaymentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<GuestPayment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GuestPaymentEdge = {
  __typename?: 'GuestPaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GuestPayment>;
};

export type GuestToken = {
  __typename?: 'GuestToken';
  embedUrl: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type HasAnyOfPermissions = {
  __typename?: 'HasAnyOfPermissions';
  permissions?: Maybe<Scalars['JSON']['output']>;
};

export type Headers = {
  __typename?: 'Headers';
  h1?: Maybe<TextConfig>;
  h2?: Maybe<TextConfig>;
  h3?: Maybe<TextConfig>;
  h4?: Maybe<TextConfig>;
  h5?: Maybe<TextConfig>;
  h6?: Maybe<TextConfig>;
};

export type HeadersInput = {
  h1?: InputMaybe<TextConfigInput>;
  h2?: InputMaybe<TextConfigInput>;
  h3?: InputMaybe<TextConfigInput>;
  h4?: InputMaybe<TextConfigInput>;
  h5?: InputMaybe<TextConfigInput>;
  h6?: InputMaybe<TextConfigInput>;
};

export type Host = {
  __typename?: 'Host';
  host: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum IconPack {
  Default = 'default',
  Filled = 'filled'
}

export type Images = {
  __typename?: 'Images';
  logo: Scalars['String']['output'];
};

export type ImagesInput = {
  logo: Scalars['String']['input'];
};

export type ImagesPerTheme = {
  __typename?: 'ImagesPerTheme';
  favicon?: Maybe<Scalars['String']['output']>;
};

export type ImagesPerThemeInput = {
  favicon: Scalars['String']['input'];
};

export enum InquiryApplicationStatusesEnum {
  Closed = 'closed',
  Open = 'open'
}

export type InquiryTag = {
  __typename?: 'InquiryTag';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** An integration */
export type Integration = {
  __typename?: 'Integration';
  description: Scalars['String']['output'];
  documentationUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  stage: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  allocatedCents?: Maybe<Scalars['Int']['output']>;
  allocations?: Maybe<Array<Allocation>>;
  analysisReport?: Maybe<AnalysisReport>;
  balanceCents?: Maybe<Scalars['Int']['output']>;
  billingPeriodFrom?: Maybe<Scalars['ISO8601Date']['output']>;
  billingPeriodTo?: Maybe<Scalars['ISO8601Date']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  displayInvoiceNumber?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  invoiceNumber?: Maybe<Scalars['Int']['output']>;
  invoiceType?: Maybe<InvoiceType>;
  issueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  issuedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lineItems?: Maybe<Array<LineItem>>;
  metadata?: Maybe<Scalars['Metadata']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Recipient>;
  referencedInvoices?: Maybe<Array<InvoiceReference>>;
  requiresAction?: Maybe<Scalars['Boolean']['output']>;
  sender?: Maybe<Sender>;
  status?: Maybe<Scalars['String']['output']>;
  taxRegime?: Maybe<Scalars['String']['output']>;
  taxesSummary?: Maybe<TaxesSummary>;
  totalCents?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
  workspaceUuid?: Maybe<Scalars['ID']['output']>;
};

/** The connection type for Invoice. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

export type InvoiceReference = {
  __typename?: 'InvoiceReference';
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoiceUuid?: Maybe<Scalars['ID']['output']>;
  issueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type InvoiceType = {
  __typename?: 'InvoiceType';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  kind: Scalars['String']['output'];
};

export type InvoicingMutations = {
  __typename?: 'InvoicingMutations';
  allocateDocument?: Maybe<AllocateDocumentMutations>;
  /** @deprecated renamed to allocateDocument */
  manualAllocate?: Maybe<ManualAllocateMutations>;
  /** @deprecated renamed to unallocateDocument */
  manualUnallocate?: Maybe<ManualUnallocateMutations>;
  recordInvoicePayment?: Maybe<RecordInvoicePaymentMutations>;
  refund?: Maybe<RefundMutations>;
  unallocateDocument?: Maybe<UnallocateDocumentMutations>;
};

export type InvoicingQueries = {
  __typename?: 'InvoicingQueries';
  allocatableDocuments?: Maybe<InvoiceConnection>;
};


export type InvoicingQueriesAllocatableDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  uuid: Scalars['String']['input'];
};

export type Jwk = {
  __typename?: 'JWK';
  e: Scalars['String']['output'];
  kid: Scalars['String']['output'];
  kty: Scalars['String']['output'];
  n: Scalars['String']['output'];
};

export type JwkResponse = ErrorMessage | Jwk;

export type Lead = {
  __typename?: 'Lead';
  agentCommission?: Maybe<Scalars['Float']['output']>;
  applicationStatus: InquiryApplicationStatusesEnum;
  budgetPreference?: Maybe<Scalars['Float']['output']>;
  buildings?: Maybe<Array<Building>>;
  crmContact?: Maybe<CrmContact>;
  dateFlexibilityInDays?: Maybe<Scalars['Int']['output']>;
  estimatedCheckIn?: Maybe<Scalars['ISO8601Date']['output']>;
  estimatedCheckOut?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['String']['output'];
  leadStatus?: Maybe<LeadStatus>;
  multiUnitGroups?: Maybe<Array<MultiUnitGroup>>;
  notes?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  purpose?: Maybe<Array<Scalars['String']['output']>>;
  source?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<InquiryTag>>;
  title?: Maybe<Scalars['String']['output']>;
  totalAdults?: Maybe<Scalars['Int']['output']>;
  totalBeds?: Maybe<Scalars['Int']['output']>;
  totalChildren?: Maybe<Scalars['Int']['output']>;
  totalUnits?: Maybe<Scalars['Int']['output']>;
  uniqueReference: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

/** The connection type for Lead. */
export type LeadConnection = {
  __typename?: 'LeadConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LeadEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Lead>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LeadEdge = {
  __typename?: 'LeadEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Lead>;
};

export type LeadStatus = {
  __typename?: 'LeadStatus';
  id: Scalars['Int']['output'];
  internalReference: Scalars['String']['output'];
  stepResult: Scalars['String']['output'];
};

export type LeadsSearchQuery = {
  agentCommission?: InputMaybe<Scalars['Float']['input']>;
  applicationStatus?: InputMaybe<Scalars['String']['input']>;
  budgetPreference?: InputMaybe<Scalars['Float']['input']>;
  dateFlexibilityInDays?: InputMaybe<Scalars['Int']['input']>;
  estimatedCheckIn?: InputMaybe<Scalars['ISO8601Date']['input']>;
  estimatedCheckOut?: InputMaybe<Scalars['ISO8601Date']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  purpose?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  totalAdults?: InputMaybe<Scalars['Int']['input']>;
  totalBeds?: InputMaybe<Scalars['Int']['input']>;
  totalChildren?: InputMaybe<Scalars['Int']['input']>;
  totalUnits?: InputMaybe<Scalars['Int']['input']>;
  uniqueReference?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  description?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['Metadata']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  unitGrossAmountCents?: Maybe<Scalars['Int']['output']>;
  unitNetAmountCents?: Maybe<Scalars['Int']['output']>;
  vat?: Maybe<VatDefinition>;
  vatPercentage?: Maybe<Scalars['Float']['output']>;
};

/** Autogenerated input type of ManualAllocateMutation */
export type ManualAllocateMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documents: Array<DocumentAllocationInput>;
  uuid: Scalars['ID']['input'];
};

export type ManualAllocateMutations = {
  __typename?: 'ManualAllocateMutations';
  invoice?: Maybe<Invoice>;
};


export type ManualAllocateMutationsInvoiceArgs = {
  input: ManualAllocateMutationInput;
};

/** Autogenerated input type of ManualUnallocateMutation */
export type ManualUnallocateMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documents: Array<DocumentAllocationInput>;
  uuid: Scalars['ID']['input'];
};

export type ManualUnallocateMutations = {
  __typename?: 'ManualUnallocateMutations';
  invoice?: Maybe<Invoice>;
};


export type ManualUnallocateMutationsInvoiceArgs = {
  input: ManualUnallocateMutationInput;
};

export type MarketplaceApp = {
  __typename?: 'MarketplaceApp';
  id?: Maybe<Scalars['String']['output']>;
};

export type MarketplaceAppInput = {
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  installNotes: Scalars['String']['input'];
  logoUrl: Scalars['String']['input'];
  privacyPolicy: Scalars['String']['input'];
  scopes: Array<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  webhooks: Scalars['Boolean']['input'];
  website: Scalars['String']['input'];
};

export type MarketplaceQueries = {
  __typename?: 'MarketplaceQueries';
  /** Marketplace root query */
  Marketplace?: Maybe<Scalars['String']['output']>;
};

export type MoveBookingMutation = {
  __typename?: 'MoveBookingMutation';
  bookings?: Maybe<Array<Scalars['ID']['output']>>;
};

/** Autogenerated input type of MoveBookingMutation */
export type MoveBookingMutationInput = {
  bookings: Array<MoveBookingsInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type MoveBookingsInput = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['ID']['input'];
};

export type MultiUnitGroup = {
  __typename?: 'MultiUnitGroup';
  id?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bookings?: Maybe<BookingsMutations>;
  createBrand: BrandResponse;
  createMagicSession?: Maybe<UserTokenResponse>;
  createUser?: Maybe<UserResponse>;
  deleteBrand: ErrorMessage;
  forgetPassword?: Maybe<UserTokenResponse>;
  invoicing?: Maybe<InvoicingMutations>;
  marketplace?: Maybe<MarketplaceApp>;
  offers?: Maybe<OfferMutations>;
  payments?: Maybe<PaymentsMutations>;
  pricing?: Maybe<PricingMutations>;
  resetPassword: ResetPasswordResponse;
  residentApp: ResidentAppMutations;
  spaces?: Maybe<SpacesMutations>;
  updateBrand: BrandResponse;
  userPreferences?: Maybe<Preferences>;
  userSignIn: UserTokenResponse;
};


export type MutationCreateBrandArgs = {
  brand: BrandInput;
};


export type MutationCreateMagicSessionArgs = {
  input: UserInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationDeleteBrandArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationForgetPasswordArgs = {
  input: ForgetPasswordInput;
};


export type MutationMarketplaceArgs = {
  input: CreateMarketplaceAppMutationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSpacesArgs = {
  input: BatchUpdateTariffsInput;
};


export type MutationUpdateBrandArgs = {
  brand: BrandInput;
  id: Scalars['UUID']['input'];
};


export type MutationUserPreferencesArgs = {
  input: PreferencesInput;
};


export type MutationUserSignInArgs = {
  input: UserSignInInput;
};

export type NotificationFonts = {
  __typename?: 'NotificationFonts';
  bodyFont: Array<Maybe<Scalars['String']['output']>>;
  headerFont: Array<Maybe<Scalars['String']['output']>>;
};

export type NotificationFontsInput = {
  bodyFont: Array<InputMaybe<Scalars['String']['input']>>;
  headerFont: Array<InputMaybe<Scalars['String']['input']>>;
};

export type OfferMutations = {
  __typename?: 'OfferMutations';
  cloneOffer?: Maybe<CloneMutation>;
};


export type OfferMutationsCloneOfferArgs = {
  input: CloneOfferMutationInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PaymentGatewayTypeEnum {
  Spreedly = 'spreedly',
  Stripe = 'stripe'
}

export type PaymentGateways = {
  __typename?: 'PaymentGateways';
  companyAddress?: Maybe<Scalars['String']['output']>;
  companyPhone?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  liveMode: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  primary: Scalars['Boolean']['output'];
  publishableKey: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** The connection type for PaymentGateways. */
export type PaymentGatewaysConnection = {
  __typename?: 'PaymentGatewaysConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentGatewaysEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PaymentGateways>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentGatewaysEdge = {
  __typename?: 'PaymentGatewaysEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PaymentGateways>;
};

export type PaymentInput = {
  amount: Scalars['Float']['input'];
  cardLastFour?: InputMaybe<Scalars['String']['input']>;
  cardType?: InputMaybe<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  payerToken: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
  paymentReference?: InputMaybe<Scalars['String']['input']>;
  processedAt?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  clientSecret: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type PaymentIntentInput = {
  amount: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  label: Scalars['String']['input'];
  payerToken: Scalars['String']['input'];
  paymentGatewayId: Scalars['Int']['input'];
  paymentReference: Scalars['String']['input'];
};

export type PaymentRecordedInput = {
  amount: Scalars['Float']['input'];
  cardLastFour?: InputMaybe<Scalars['String']['input']>;
  cardType?: InputMaybe<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  label: Scalars['String']['input'];
  payerToken: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
  paymentReference?: InputMaybe<Scalars['String']['input']>;
  processError?: InputMaybe<Scalars['String']['input']>;
  processedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  status: Scalars['String']['input'];
};

export enum PaymentStatus {
  Due = 'DUE',
  InProcess = 'IN_PROCESS',
  Overdue = 'OVERDUE',
  Success = 'SUCCESS'
}

export type PaymentsMutations = {
  __typename?: 'PaymentsMutations';
  createPaymentIntent?: Maybe<PaymentIntent>;
  recordPayment?: Maybe<GuestPayment>;
  refundPayment?: Maybe<GuestPayment>;
};


export type PaymentsMutationsCreatePaymentIntentArgs = {
  input: CreatePaymentIntentMutationInput;
};


export type PaymentsMutationsRecordPaymentArgs = {
  input: RecordPaymentMutationInput;
};


export type PaymentsMutationsRefundPaymentArgs = {
  input: RefundPaymentMutationInput;
};

export type PaymentsQueries = {
  __typename?: 'PaymentsQueries';
  guestPayments?: Maybe<GuestPaymentConnection>;
  paymentGateways?: Maybe<PaymentGatewaysConnection>;
};


export type PaymentsQueriesGuestPaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bookingId: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PaymentsQueriesPaymentGatewaysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type Person = {
  __typename?: 'Person';
  contact: Contact;
  roles: Array<Role>;
};

export type Pms = {
  __typename?: 'Pms';
  hasAnyOfPermissions?: Maybe<HasAnyOfPermissions>;
  userWorkspacePermissions?: Maybe<UserWorkspacePermissions>;
  userWorkspacesWithPermissions?: Maybe<UserWorkspacesWithPermissions>;
};


export type PmsHasAnyOfPermissionsArgs = {
  permissions: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  workspaces: Array<Scalars['String']['input']>;
};


export type PmsUserWorkspacePermissionsArgs = {
  permissions: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};


export type PmsUserWorkspacesWithPermissionsArgs = {
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['String']['input'];
};

export type Portfolio = {
  __typename?: 'Portfolio';
  buildingIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type Preferences = {
  __typename?: 'Preferences';
  portfolio?: Maybe<Portfolio>;
};

/** Autogenerated input type of Preferences */
export type PreferencesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  portfolio?: InputMaybe<UserPreferenceInput>;
};

export type PriceInput = {
  adjustment: PricingAdjustmentsEnum;
  amount: Scalars['Int']['input'];
};

export enum PricingAdjustmentsEnum {
  Fixed = 'fixed',
  Percentage = 'percentage',
  Relative = 'relative'
}

export enum PricingIntervalEnum {
  CalendarMonthly = 'calendar_monthly',
  Nightly = 'nightly'
}

export type PricingMutations = {
  __typename?: 'PricingMutations';
  ratePlans?: Maybe<RatePlanMutations>;
};

export enum PricingSpaceTypesEnum {
  MultiUnitGroup = 'MULTI_UNIT_GROUP',
  Property = 'PROPERTY'
}

/** The query root of this schema */
export type Query = {
  __typename?: 'Query';
  authIdentity?: Maybe<AuthIdentityQueries>;
  authJWK: JwkResponse;
  availability?: Maybe<Availability>;
  awc?: Maybe<AwcQueries>;
  bookings?: Maybe<BookingsQueries>;
  brand: BrandResponse;
  brands?: Maybe<Array<Maybe<Brand>>>;
  buildings?: Maybe<BuildingConnection>;
  crm?: Maybe<CrmQueries>;
  dbwSite?: Maybe<DbwSite>;
  dna: Dna;
  forex: Forex;
  healthCheck?: Maybe<Scalars['String']['output']>;
  /** Find an integration by ID */
  integration?: Maybe<Integration>;
  /** Get all integrations */
  integrations?: Maybe<Array<Integration>>;
  invoicing?: Maybe<InvoicingQueries>;
  marketplace?: Maybe<MarketplaceQueries>;
  payments?: Maybe<PaymentsQueries>;
  residentApp: ResidentApp;
  spaces?: Maybe<SpacesQueries>;
  users?: Maybe<UserQueries>;
};


/** The query root of this schema */
export type QueryAuthJwkArgs = {
  kid: Scalars['UUID']['input'];
};


/** The query root of this schema */
export type QueryBrandArgs = {
  id: Scalars['UUID']['input'];
};


/** The query root of this schema */
export type QueryBuildingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<BuildingsSearchQuery>;
};


/** The query root of this schema */
export type QueryDbwSiteArgs = {
  q?: InputMaybe<DbwSitesSearchQuery>;
};


/** The query root of this schema */
export type QueryIntegrationArgs = {
  id: Scalars['ID']['input'];
};

export enum Radius {
  Full = 'full',
  Large = 'large',
  Medium = 'medium',
  None = 'none',
  Small = 'small'
}

export type Rate = {
  __typename?: 'Rate';
  currencyFrom: Scalars['String']['output'];
  currencyTo: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export type RatePlan = {
  __typename?: 'RatePlan';
  active?: Maybe<Scalars['Boolean']['output']>;
  cancellationPolicyId?: Maybe<Scalars['String']['output']>;
  channelListingId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  maxStay?: Maybe<Scalars['Int']['output']>;
  maximumBookingWindow?: Maybe<Scalars['Int']['output']>;
  maximumBookingWindowUnit?: Maybe<BookingWindow>;
  minStay?: Maybe<Scalars['Int']['output']>;
  minimumBookingWindow?: Maybe<Scalars['Int']['output']>;
  minimumBookingWindowUnit?: Maybe<BookingWindow>;
  multiUnitGroupId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentRatePlanId?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
  propertyId?: Maybe<Scalars['String']['output']>;
  readOnly?: Maybe<Scalars['Boolean']['output']>;
};

export type RatePlanMutations = {
  __typename?: 'RatePlanMutations';
  updateRatePlans?: Maybe<UpdateRatePlansMutation>;
};


export type RatePlanMutationsUpdateRatePlansArgs = {
  input: UpdateRatePlansMutationInput;
};

export type RatePlansInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  cancellationPolicyId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  maxStay?: InputMaybe<Scalars['Int']['input']>;
  maximumBookingWindow?: InputMaybe<Scalars['Int']['input']>;
  maximumBookingWindowUnit?: InputMaybe<BookingWindow>;
  minStay?: InputMaybe<Scalars['Int']['input']>;
  minimumBookingWindow?: InputMaybe<Scalars['Int']['input']>;
  minimumBookingWindowUnit?: InputMaybe<BookingWindow>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentRatePlanId?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['Int']['input']>;
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Recipient = {
  __typename?: 'Recipient';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']['output']>;
  idDocumentNumber?: Maybe<Scalars['String']['output']>;
  idDocumentType?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nationalityCode?: Maybe<Scalars['String']['output']>;
  taxNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RecordInvoicePaymentMutation */
export type RecordInvoicePaymentMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  payment: PaymentInput;
  uuid: Scalars['String']['input'];
};

export type RecordInvoicePaymentMutations = {
  __typename?: 'RecordInvoicePaymentMutations';
  invoice?: Maybe<Invoice>;
};


export type RecordInvoicePaymentMutationsInvoiceArgs = {
  input: RecordInvoicePaymentMutationInput;
};

/** Autogenerated input type of RecordPaymentMutation */
export type RecordPaymentMutationInput = {
  bookingId: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  payment: PaymentRecordedInput;
};

export type RefundGuestPayment = {
  __typename?: 'RefundGuestPayment';
  amount: Scalars['Float']['output'];
  automatic?: Maybe<Scalars['Boolean']['output']>;
  cardLastFour?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  documentUuid?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['String']['output'];
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['String']['output']>;
  payerName?: Maybe<Scalars['String']['output']>;
  payerToken?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentReference?: Maybe<Scalars['String']['output']>;
  payoutMethod?: Maybe<Scalars['String']['output']>;
  payoutSource?: Maybe<Scalars['String']['output']>;
  processError?: Maybe<Scalars['String']['output']>;
  processedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  refundedAmount?: Maybe<Scalars['Float']['output']>;
  refundedPayments?: Maybe<RefundGuestPaymentConnection>;
  status?: Maybe<Scalars['String']['output']>;
};


export type RefundGuestPaymentRefundedPaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for RefundGuestPayment. */
export type RefundGuestPaymentConnection = {
  __typename?: 'RefundGuestPaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RefundGuestPaymentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RefundGuestPayment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RefundGuestPaymentEdge = {
  __typename?: 'RefundGuestPaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RefundGuestPayment>;
};

/** Autogenerated input type of RefundMutation */
export type RefundMutationInput = {
  amountCents: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

export type RefundMutations = {
  __typename?: 'RefundMutations';
  invoice?: Maybe<Invoice>;
};


export type RefundMutationsInvoiceArgs = {
  input: RefundMutationInput;
};

/** Autogenerated input type of RefundPaymentMutation */
export type RefundPaymentMutationInput = {
  amountCents: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  paymentId: Scalars['Int']['input'];
  workspaceId: Scalars['Int']['input'];
};

export type ResetPasswordInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ResetPasswordResponse = ErrorMessage | User;

export type ResidentApp = {
  __typename?: 'ResidentApp';
  config: ResidentAppConfig;
  configs: Array<ResidentAppConfig>;
  /** Find a user's booking by ID. The user's ID is inferred from the authorization header */
  userBooking: ResidentAppBooking;
  /** Find a user's bookings. The user's ID is inferred from the authorization header */
  userBookings: Array<ResidentAppBooking>;
};


export type ResidentAppConfigArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type ResidentAppUserBookingArgs = {
  bookingId: Scalars['String']['input'];
  configId: Scalars['String']['input'];
};


export type ResidentAppUserBookingsArgs = {
  configId: Scalars['String']['input'];
};

export type ResidentAppBooking = {
  __typename?: 'ResidentAppBooking';
  bookingId: Scalars['String']['output'];
  building: ResidentAppBuilding;
  checkInDate?: Maybe<Scalars['DateTime']['output']>;
  checkOutDate?: Maybe<Scalars['DateTime']['output']>;
  confirmationCode: Scalars['String']['output'];
  coverImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  leadGuest?: Maybe<ResidentAppBookingGuest>;
  marketingTitle: Scalars['String']['output'];
  numberOfGuests: Scalars['Int']['output'];
  status?: Maybe<BookingStatus>;
  unitId?: Maybe<Scalars['String']['output']>;
  unitTypeId?: Maybe<Scalars['String']['output']>;
};

export type ResidentAppBookingGuest = {
  __typename?: 'ResidentAppBookingGuest';
  name: Scalars['String']['output'];
  roles: Array<Role>;
};

export type ResidentAppBuilding = {
  __typename?: 'ResidentAppBuilding';
  address: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ResidentAppConfig = {
  __typename?: 'ResidentAppConfig';
  apiKey?: Maybe<Scalars['String']['output']>;
  /** The auth0 client ID for the application */
  authClientId: Scalars['String']['output'];
  /** The encrypted auth0 client secret for the application */
  authClientSecret: Scalars['String']['output'];
  /** The auth0 issuer URL for the application */
  authIssuerUrl: Scalars['String']['output'];
  brandId: Scalars['String']['output'];
  cmsApiKey?: Maybe<Scalars['String']['output']>;
  colorway: ColorwayName;
  createdAt: Scalars['DateTime']['output'];
  domains: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ResidentAppDeleteResult = {
  __typename?: 'ResidentAppDeleteResult';
  deletedCount: Scalars['Int']['output'];
};

export type ResidentAppMutations = {
  __typename?: 'ResidentAppMutations';
  createConfig: ResidentAppConfig;
  createUserBooking: ResidentAppBooking;
  deleteUserBookings: ResidentAppDeleteResult;
  updateConfig: ResidentAppConfig;
};


export type ResidentAppMutationsCreateConfigArgs = {
  createResidentAppConfigInput: CreateResidentAppConfigInput;
};


export type ResidentAppMutationsCreateUserBookingArgs = {
  createUserBookingInput: CreateUserBookingInput;
};


export type ResidentAppMutationsDeleteUserBookingsArgs = {
  deleteUserBookingsInput: DeleteUserBookingsInput;
};


export type ResidentAppMutationsUpdateConfigArgs = {
  updateResidentAppConfigInput: UpdateResidentAppConfigInput;
};

export enum Role {
  Agent = 'AGENT',
  Guest = 'GUEST',
  LeadGuest = 'LEAD_GUEST',
  Signatory = 'SIGNATORY'
}

export type Rounding = {
  __typename?: 'Rounding';
  buttons?: Maybe<Radius>;
  other?: Maybe<Radius>;
};

export type RoundingInput = {
  buttons?: InputMaybe<Radius>;
  other?: InputMaybe<Radius>;
};

export type SearchTerm = {
  __typename?: 'SearchTerm';
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** The connection type for SearchTerm. */
export type SearchTermConnection = {
  __typename?: 'SearchTermConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SearchTermEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SearchTerm>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SearchTermEdge = {
  __typename?: 'SearchTermEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SearchTerm>;
};

export type SearchTermType = {
  __typename?: 'SearchTermType';
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  placeholder?: Maybe<Scalars['String']['output']>;
  renderOrder: Scalars['Int']['output'];
  searchTerms: Array<SearchTerm>;
  showOnLanding: Scalars['Boolean']['output'];
  showOnProperties: Scalars['Boolean']['output'];
  typeKey: Scalars['String']['output'];
};

/** The connection type for SearchTermType. */
export type SearchTermTypeConnection = {
  __typename?: 'SearchTermTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SearchTermTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SearchTermType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SearchTermTypeEdge = {
  __typename?: 'SearchTermTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SearchTermType>;
};

export type Sender = {
  __typename?: 'Sender';
  address?: Maybe<Address>;
  bankDetails?: Maybe<BankDetails>;
  companyLogoUrl?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyRegNo?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  publicCompanyName?: Maybe<Scalars['String']['output']>;
  taxIdentifier?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export enum SepaStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type Social = {
  __typename?: 'Social';
  type: SocialType;
  url: Scalars['String']['output'];
};

export type SocialInput = {
  type: SocialType;
  url: Scalars['String']['input'];
};

export enum SocialType {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Twitter = 'twitter',
  Wechat = 'wechat',
  Youtube = 'youtube'
}

export type SpaceInput = {
  id: Scalars['Int']['input'];
  type: PricingSpaceTypesEnum;
};

export type SpacesAddressType = {
  __typename?: 'SpacesAddressType';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
};

export type SpacesBuildingType = {
  __typename?: 'SpacesBuildingType';
  address?: Maybe<SpacesAddressType>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SpacesMutations = {
  __typename?: 'SpacesMutations';
  batchUpdateTariffs?: Maybe<BatchUpdateTariffs>;
};

export type SpacesQueries = {
  __typename?: 'SpacesQueries';
  unitTypes?: Maybe<UnitTypes>;
  units?: Maybe<Units>;
};

export type SplitBookingMutation = {
  __typename?: 'SplitBookingMutation';
  bookings?: Maybe<Array<Scalars['ID']['output']>>;
};

/** Autogenerated input type of SplitBookingMutation */
export type SplitBookingMutationInput = {
  bookings: Array<SplitBookingsInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type SplitBookingsInput = {
  checkIn?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  checkOut?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  unitId: Scalars['ID']['input'];
};

export type Superset = {
  __typename?: 'Superset';
  dashboards: Array<Dashboard>;
  guestToken: GuestToken;
};

export type TaxSummaryItem = {
  __typename?: 'TaxSummaryItem';
  amountCents: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  metadata?: Maybe<Scalars['Metadata']['output']>;
};

export type TaxesSummary = {
  __typename?: 'TaxesSummary';
  items?: Maybe<Array<TaxSummaryItem>>;
};

export type TextConfig = {
  __typename?: 'TextConfig';
  fontSize?: Maybe<FontSize>;
  fontVariant?: Maybe<FontVariant>;
  fontWeight?: Maybe<FontWeight>;
};

export type TextConfigInput = {
  fontSize?: InputMaybe<FontSize>;
  fontVariant?: InputMaybe<FontVariant>;
  fontWeight?: InputMaybe<FontWeight>;
};

export type Theme = {
  __typename?: 'Theme';
  alerts: Alerts;
  borders?: Maybe<Borders>;
  buttonFontWeight?: Maybe<FontWeight>;
  colorways: Colorways;
  elevation?: Maybe<Elevation>;
  fonts?: Maybe<Fonts>;
  formFieldStyle?: Maybe<FormFieldStyle>;
  gap?: Maybe<Gap>;
  iconPack?: Maybe<IconPack>;
  images?: Maybe<ImagesPerTheme>;
  rounding?: Maybe<Rounding>;
  typography?: Maybe<Typography>;
};

export type ThemeInput = {
  alerts: AlertsInput;
  borders?: InputMaybe<BordersInput>;
  buttonFontWeight?: InputMaybe<FontWeight>;
  colorways: ColorwaysInput;
  elevation?: InputMaybe<Elevation>;
  fonts?: InputMaybe<FontsInput>;
  formFieldStyle?: InputMaybe<FormFieldStyle>;
  gap?: InputMaybe<Gap>;
  iconPack?: InputMaybe<IconPack>;
  images?: InputMaybe<ImagesPerThemeInput>;
  rounding?: InputMaybe<RoundingInput>;
  typography?: InputMaybe<TypographyInput>;
};

export type Typography = {
  __typename?: 'Typography';
  body: TextConfig;
  headers: Headers;
  jumbotron?: Maybe<TextConfig>;
  subtitle?: Maybe<TextConfig>;
};

export type TypographyInput = {
  body: TextConfigInput;
  headers: HeadersInput;
  jumbotron?: InputMaybe<TextConfigInput>;
  subtitle?: InputMaybe<TextConfigInput>;
};

/** Autogenerated input type of UnallocateDocumentMutation */
export type UnallocateDocumentMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documents: Array<DocumentAllocationInput>;
  uuid: Scalars['ID']['input'];
};

export type UnallocateDocumentMutations = {
  __typename?: 'UnallocateDocumentMutations';
  document?: Maybe<Document>;
};


export type UnallocateDocumentMutationsDocumentArgs = {
  input: UnallocateDocumentMutationInput;
};

export type Unit = {
  __typename?: 'Unit';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status?: Maybe<UnitStatus>;
  unitType?: Maybe<UnitType>;
};

export type UnitAvailability = {
  __typename?: 'UnitAvailability';
  availability?: Maybe<AvailabilityStatus>;
  /** @deprecated Use availability field instead */
  available?: Maybe<Scalars['Boolean']['output']>;
  unit?: Maybe<Unit>;
};

/** The connection type for UnitAvailability. */
export type UnitAvailabilityConnection = {
  __typename?: 'UnitAvailabilityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UnitAvailabilityEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UnitAvailability>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UnitAvailabilityEdge = {
  __typename?: 'UnitAvailabilityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UnitAvailability>;
};

/** The connection type for Unit. */
export type UnitConnection = {
  __typename?: 'UnitConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UnitEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Unit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UnitEdge = {
  __typename?: 'UnitEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Unit>;
};

export enum UnitStatus {
  Active = 'ACTIVE',
  Churned = 'CHURNED',
  Creating = 'CREATING',
  Importing = 'IMPORTING',
  Inactive = 'INACTIVE',
  PartiallyImported = 'PARTIALLY_IMPORTED'
}

export type UnitType = {
  __typename?: 'UnitType';
  building?: Maybe<SpacesBuildingType>;
  /** @deprecated This field is deprecated, use building instead when available */
  buildingId?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  marketingTitle?: Maybe<Scalars['String']['output']>;
};

/** The connection type for UnitType. */
export type UnitTypeConnection = {
  __typename?: 'UnitTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UnitTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UnitType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UnitTypeEdge = {
  __typename?: 'UnitTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UnitType>;
};

export enum UnitTypeStatus {
  Active = 'ACTIVE',
  Churned = 'CHURNED',
  Creating = 'CREATING',
  Inactive = 'INACTIVE'
}

export type UnitTypes = {
  __typename?: 'UnitTypes';
  search?: Maybe<UnitTypeConnection>;
};


export type UnitTypesSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<UnitTypesSearchInput>;
};

export type UnitTypesSearchInput = {
  buildingIds?: InputMaybe<Array<Scalars['String']['input']>>;
  code?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<UnitTypeStatus>;
  workspaceIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Units = {
  __typename?: 'Units';
  search?: Maybe<UnitConnection>;
};


export type UnitsSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<UnitsSearchInput>;
};

export type UnitsAvailabilityInput = {
  endDate: Scalars['ISO8601Date']['input'];
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  showUnavailable?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['ISO8601Date']['input'];
};

export type UnitsSearchInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<UnitStatus>;
  unitTypeIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateRatePlansMutation = {
  __typename?: 'UpdateRatePlansMutation';
  ratePlans?: Maybe<Array<RatePlan>>;
};

/** Autogenerated input type of UpdateRatePlansMutation */
export type UpdateRatePlansMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ratePlans: Array<RatePlansInput>;
};

export type UpdateResidentAppConfigInput = {
  authClientId?: InputMaybe<Scalars['String']['input']>;
  authClientSecret?: InputMaybe<Scalars['String']['input']>;
  authIssuerUrl?: InputMaybe<Scalars['String']['input']>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  cmsApiKey?: InputMaybe<Scalars['String']['input']>;
  colorway?: InputMaybe<ColorwayName>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalContactId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  siteId: Scalars['String']['output'];
};

export type UserInput = {
  email: Scalars['String']['input'];
  externalContactId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type UserPreferenceInput = {
  buildingIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserQueries = {
  __typename?: 'UserQueries';
  whoami?: Maybe<Whoami>;
};

export type UserResponse = ErrorMessage | User;

export type UserSignInInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
};

export type UserToken = {
  __typename?: 'UserToken';
  token: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type UserTokenResponse = ErrorMessage | UserToken;

export type UserWorkspacePermissions = {
  __typename?: 'UserWorkspacePermissions';
  permissions?: Maybe<Scalars['JSON']['output']>;
};

export type UserWorkspacesWithPermissions = {
  __typename?: 'UserWorkspacesWithPermissions';
  permissions?: Maybe<Scalars['JSON']['output']>;
};

export type VatDefinition = {
  __typename?: 'VatDefinition';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type Vetting = {
  __typename?: 'Vetting';
  status?: Maybe<VettingStatus>;
};

export enum VettingStatus {
  Approved = 'APPROVED',
  Failed = 'FAILED',
  NeedsReview = 'NEEDS_REVIEW',
  Pending = 'PENDING',
  Unvetted = 'UNVETTED'
}

export type Whoami = {
  __typename?: 'Whoami';
  avatar?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<Preferences>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type Workspace = {
  __typename?: 'Workspace';
  crmNamespace?: Maybe<CrmNamespace>;
  demo: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/** The connection type for Workspace. */
export type WorkspaceConnection = {
  __typename?: 'WorkspaceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WorkspaceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Workspace>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WorkspaceEdge = {
  __typename?: 'WorkspaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Workspace>;
};

export type Workspaces = {
  __typename?: 'Workspaces';
  findOne?: Maybe<Workspace>;
  search?: Maybe<WorkspaceConnection>;
};


export type WorkspacesFindOneArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type WorkspacesSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<WorkspacesSearchInput>;
};

export type WorkspacesSearchInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};
