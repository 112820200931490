import MuiBox, { type BoxProps as MuiBoxProps } from "@mui/material/Box";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { BoxTypeMap } from "@mui/system/Box";
import type { Theme } from "@mui/material/styles";

export type BoxProps = MuiBoxProps;

// TypeScript complains if we don't explicitly define the type of the component
export const Box: OverridableComponent<BoxTypeMap<object, "div", Theme>> =
  MuiBox;
