import { TextConfig } from "../../graphql/generated";
import { GetThemeConfig } from "../theme.types";
import { getFontSize } from "../utils/get-font-size";
import { getFontWeight } from "../utils/get-font-weight";
import { getFontFamilies } from "../utils/get-font-families";
// eslint-disable-next-line no-restricted-imports
import { type TypographyStyleOptions } from "@mui/material/styles/createTypography";
import { TypographyVariant } from "@mui/material/styles";
import { getFontLineHeight } from "../utils/get-font-line-height";

type GetTypographyConfigOptions = {
  config: TextConfig | null | undefined;
  fontFamily: string | undefined | null;
  color?: string;
  variant: TypographyVariant;
};

function getTypographyConfig({
  config,
  fontFamily,
  color,
  variant,
}: GetTypographyConfigOptions): TypographyStyleOptions {
  return {
    fontSize: getFontSize(variant),
    lineHeight: getFontLineHeight(variant),
    fontWeight: getFontWeight(config?.fontWeight),
    fontFamily: getFontFamilies(fontFamily),
    color,
  };
}

export const getThemeTypography: GetThemeConfig<"typography"> = ({
  theme,
  colorway,
}) => {
  const bodyFont = theme.fonts?.bodyFont;
  const headerFont = theme.fonts?.headerFont;

  return {
    body1: getTypographyConfig({
      fontFamily: bodyFont,
      config: theme.typography?.body,
      variant: "body1",
    }),
    body2: getTypographyConfig({
      fontFamily: bodyFont,
      config: theme.typography?.body,
      variant: "body2",
    }),
    h1: getTypographyConfig({
      fontFamily: headerFont,
      config: theme.typography?.headers.h1,
      color: colorway?.headerText,
      variant: "h1",
    }),
    h2: getTypographyConfig({
      fontFamily: headerFont,
      config: theme.typography?.headers.h2,
      color: colorway?.headerText,
      variant: "h2",
    }),
    h3: getTypographyConfig({
      fontFamily: headerFont,
      config: theme.typography?.headers.h3,
      color: colorway?.headerText,
      variant: "h3",
    }),
    subtitle1: getTypographyConfig({
      fontFamily: bodyFont,
      config: theme.typography?.subtitle,
      color: colorway?.subtitleText,
      variant: "subtitle1",
    }),
    button: {
      fontFamily: bodyFont,
      fontWeight: getFontWeight(theme.buttonFontWeight),
    },
  };
};
