"use client";

import { useCallback, useMemo } from "react";
import clamp from "lodash/clamp";
import { useControllableState } from "@xenia-libs/hooks";

export type StepConfig<Id extends string = string> = {
  id: Id;
  label: string;
};

type UseStepperOptions<Id extends string> = {
  steps: StepConfig<Id>[];
  defaultStep?: Id;
  activeStep?: Id;
  onStepChange?: (step: Id) => void;
};

export function useStepper<Id extends string>({
  steps,
  defaultStep = steps[0]?.id,
  activeStep: providedActiveStep,
  onStepChange,
}: UseStepperOptions<Id>) {
  const [activeStep, setStep] = useControllableState({
    defaultValue: defaultStep,
    value: providedActiveStep,
    onChange: onStepChange,
  });

  const findStepIndex = useCallback(
    (id: string | undefined) => steps.findIndex((step) => step.id === id),
    [steps],
  );

  const clampStep = useCallback(
    (index: number) => clamp(index, 0, steps.length - 1),
    [steps.length],
  );

  const activeIndex = useMemo(() => {
    const index = findStepIndex(activeStep);
    return index === -1 ? 0 : index;
  }, [activeStep, findStepIndex]);

  const incrementStep = useCallback(() => {
    setStep(steps[clampStep(activeIndex + 1)].id);
  }, [activeIndex, clampStep, setStep, steps]);

  const decrementStep = useCallback(() => {
    setStep(steps[clampStep(activeIndex - 1)].id);
  }, [activeIndex, clampStep, setStep, steps]);

  return {
    activeStep,
    activeIndex,
    incrementStep,
    decrementStep,
    hasNextStep: activeIndex < steps.length - 1,
    hasPreviousStep: activeIndex > 0,
    maxSteps: steps.length,
  };
}
