"use client";
import { styled } from "@mui/material/styles";
import { Link, LinkProps } from "../link";
import classNames from "classnames";
import React from "react";
import { Box } from "../layout";

/*
These elements allow for an entire area to act like a link, without having to wrap the entire area in an anchor tag.
This improves a11y and is inspired by https://chakra-ui.com/docs/components/link-overlay/usage
*/

const LinkOverlayRoot = styled(Link, {
  name: "LinkOverlay",
  slot: "Root",
})({
  position: "static",
  // Creates a pseudo element that fills up the LinkBox
  "&:before": {
    content: "''",
    cursor: "inherit",
    display: "block",
    position: "absolute",
    inset: 0,
    zIndex: 0,
  },
}) as typeof Link;

export function LinkOverlay<Component extends React.ElementType>(
  props: LinkProps<Component>,
) {
  return (
    <LinkOverlayRoot
      {...props}
      className={classNames(props.className, "link-overlay")}
    />
  );
}

export const LinkBox = styled(Box, {
  name: "LinkBox",
})({
  // Elevate the links, buttons and abbreviations up so they are clickable
  // Funky syntax translates to select all anchor elements that have a href attribute,
  // but do not have a class that ends with link-overlay
  "a[href]:not(.link-overlay), abbr[title], button": {
    position: "relative",
    zIndex: 1,
  },
  position: "relative",
}) as typeof Box;
